import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const DashboardDefault2 = Loadable(lazy(() => import('pages/dashboard/index2')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
const GroupAdd = Loadable(lazy(() => import('pages/extra-pages/GroupAdd')));
const Contact_list = Loadable(lazy(() => import('pages/extra-pages/Contact_list')));
const Userlist = Loadable(lazy(() => import('pages/extra-pages/Userlist')));
const Report = Loadable(lazy(() => import('pages/extra-pages/Report')));
const GroupId = Loadable(lazy(() => import('pages/extra-pages/GroupId')));
const Transactionhistory = Loadable(lazy(() => import('pages/extra-pages/Transactionhistory')));


// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
      {
      path: '/dashboard',
      element: <DashboardDefault2 />
    },
    {
      path: 'BuyPlan',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
     {
      path: 'dashboard',
      children: [
        {
          path: 'default2',
          element: <DashboardDefault2 />
        }
      ]
    },
    {
      path: 'Documentation',
      element: <SamplePage />
    },
     {
      path: 'Userlist',
      element: <Userlist />
    },
     {
      path: 'GroupId',
      element: <GroupId />
    },
    {
      path: 'GroupAdd',
      element: <GroupAdd />
    },
     {
      path: 'ContactList',
      element: <Contact_list />
    },
     {
      path: 'Report',
      element: <Report />
    },
    {
      path: 'Transactionhistory',
      element: <Transactionhistory />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
