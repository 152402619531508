// assets
import {
 
  ChromeOutlined,


} from '@ant-design/icons';

// icons
const icons = {

  ChromeOutlined,



};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: 'group',
  children: [
  
    {
      id: 'util-color',
      title: 'Buy Plan',
      type: 'item',
      url: '/BuyPlan',
     icon: icons.ChromeOutlined
    },
   
    
  ]
};

export default utilities;
