// assets
import {  QuestionOutlined } from '@ant-design/icons';

// icons
const icons = {
  
  QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'support',
  title: 'Support',
  type: 'group',
  children: [
    {
      id: 'Documentation',
      title: 'Api Documentation',
      type: 'item',
      url: '/Documentation',
      iicon: icons.QuestionOutlined,
       
    },
   
    {
      id: 'Report',
      title: 'Report',
      type: 'item',
      url: '/Report',
      iicon: icons.QuestionOutlined
       
    },
    {
      id: 'Transactionhistory',
      title: 'Transaction History',
      type: 'item',
      url: '/Transactionhistory',
      iicon: icons.QuestionOutlined
       
    }
    
   
 

   
  ]   
};

export default support;
