// material-ui
import {Stack} from '@mui/material';

// project import
import MainCard from 'components/MainCard';



// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavCard = () => (
  <MainCard sx={{ bgcolor: 'grey.50', m: 3 }}>
    <Stack alignItems="center" spacing={2.5}>
    
     
    </Stack>
  </MainCard>
);

export default NavCard;
