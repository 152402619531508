// material-ui
import { Box,  useMediaQuery } from '@mui/material';
import react from 'react';
import { Typography } from '@mui/material';
// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [plan,setplan] = react.useState('No Active Plan');
   const plan_id = localStorage.getItem('plan');
  react.useEffect( ()=>{

    if(plan_id != "null")
    {
      setplan(plan_id);
    }
  },[])
   
  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

          <Typography  sx={{ textDecoration: 'none',width: '20%'   }} style={{float:'right'}} color="primary">
            Active Plan : {plan}          
            </Typography>

      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
