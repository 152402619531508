import PropTypes from 'prop-types';
import { useState,useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import {  ProfileOutlined,LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = () => {
  const [plan,setplan] = useState('Plan:-No Active Plan');
  const [LastDate,setLastDate] = useState('Hii');
   const plan_id = localStorage.getItem('plan');
   const validate_until = localStorage.getItem('validate_until');

  const Navigate = useNavigate();
  const theme = useTheme();
  useEffect( ()=>{
    
    if(plan_id != "null")
    {
      setplan('Plan:-'+plan_id);
          }
          setLastDate('LastDate:-'+validate_until);
  },[]);
  const handleLogout = () =>{
   
    localStorage.clear();
    Navigate('/login');
  }
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary={plan} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary={LastDate} />
      </ListItemButton>

      
      
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
